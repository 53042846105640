import React from "react"

import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles"
import { CssBaseline, Stack } from "@mui/material"

import "@fontsource/poppins/400.css"
import "@fontsource/poppins/500.css"
import "@fontsource/poppins/600.css"

// import Design from "../components/design"
import Header from "../layout/header"
import Footer from "../layout/footer"

import { border, color } from "../settings/styles"

import { getVisitor } from "../functions/cookies"

const Wrapper = props => {
  // React.useEffect(() => {
  //   console.log("Wrapper")
  // })

  const { children } = props

  getVisitor()

  let theme = createTheme({
    palette: {
      background: color.background,
      primary: color.primary,
      secondary: color.secondary,
    },
    typography: {
      fontFamily: [
        "Poppins",
        "-apple-system",
        "BlinkMacSystemFont",
        "'Segoe UI'",
        "Roboto",
        "'Helvetica Neue'",
        "Arial",
        "sans-serif",
        "'Apple Color Emoji'",
        "'Segoe UI Emoji'",
        "'Segoe UI Symbol'",
      ].join(","),
      fontWeightBold: 600,
      h1: {
        fontSize: "3.5rem",
        fontWeight: 500,
      },
      h2: {
        fontSize: "3rem",
        fontWeight: 500,
      },
      h3: {
        fontSize: "2.2rem",
        fontWeight: 400,
      },
      h4: {
        fontSize: "1.8rem",
        fontWeight: 400,
      },
      h5: {
        fontSize: "1.6rem",
        fontWeight: 400,
      },
      h6: {
        fontSize: "1.2rem",
        fontWeight: 400,
      },
      button: {
        fontSize: "1rem",
        textTransform: "none",
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: { padding: "0 !important" },
          h1: {
            fontSize: "3.5rem",
            fontWeight: 500,
            // marginTop: 0,
          },
          h2: {
            fontSize: "3rem",
            fontWeight: 500,
            // marginTop: 0,
          },
          h3: {
            fontSize: "2.2rem",
            fontWeight: 400,
            // marginTop: 0,
          },
          p: {
            textAlign: "justify",
            // marginTop: 0,
          },
          ul: {
            marginTop: 0,
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            ":hover": {
              cursor: "pointer",
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            transitionDuration: "0ms",
            borderRadius: border.radius,
            boxShadow: "none",
            ":hover": {
              boxShadow: "none",
            },
          },
          contained: {
            borderWidth: 0,
            ":hover": {
              borderWidth: 0,
            },
          },
          outlined: {
            borderWidth: border.width,
            ":hover": {
              borderWidth: border.width,
            },
          },
          outlinedPrimary: {
            borderColor: border.color,
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: border.color,
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          input: {
            fontSize: "0.75rem",
            fontWeight: 600,
            height: 4 * 6,
          },
          root: {
            fieldset: {
              borderRadius: border.radius,
              borderWidth: border.width,
              borderColor: border.color,
            },
            ":hover": {
              fieldset: {
                borderColor: `${color.primary.main} !important`,
              },
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontSize: "0.75rem",
            lineHeight: 2,
            fontWeight: 600,
          },
          shrink: {
            transform: "translate(14px, -12px) scale(1)",
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            boxShadow: "none",
            borderRadius: border.radius,
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            boxShadow: "none",
            borderStyle: border.style,
            borderWidth: border.width,
            borderColor: border.color,
            ":first-of-type": {
              borderTopLeftRadius: border.radius,
              borderTopRightRadius: border.radius,
            },
            ":last-of-type": {
              borderBottomLeftRadius: border.radius,
              borderBottomRightRadius: border.radius,
            },
            ":before": {
              display: "none",
            },
          },
        },
      },
    },
  })
  theme = responsiveFontSizes(theme)

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {/* <Design /> */}
      <Stack spacing={6}>
        <Header />
        {children}
        <Footer />
      </Stack>
    </ThemeProvider>
  )
}

export default Wrapper
