export const base = { pixels: 4 }

export const border = {
  style: "solid",
  color: "rgba(45, 45, 46, 0.1)", // Maqual Black #2D2D2E
  radius: base.pixels * 2 + "px",
  width: "1.5px",
}

export const color = {
  background: {
    default: "#FAFAF6", // rgba(250, 250, 246, 1.0)
    paper: "#FFFFFF", // rgba(255, 255, 255, 1.0)
    insert: "#EDEEF9", // rgba(237, 238, 249, 1.0)
    skeleton: "rgba(36, 54, 245, 0.4)", // #2436F5 Maqual Blue
  },
  primary: {
    dark: "#1925AB", // rgba(25, 37, 171, 1.0)
    main: "#2436F5", // rgba(36, 54, 245, 1.0) Maqual Blue
    light: "#4F5EF7", // rgba(79, 94, 247, 1.0)
  },
  secondary: {
    dark: "#1F1F20", // rgba(31, 31, 32, 1.0)
    main: "#2D2D2E", // rgba(45, 45, 46, 1.0) Maqual Black
    light: "#575757", // rgba(87, 87, 87, 1.0)
  },
}
