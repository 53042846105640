import React from "react"

import { Stack, IconButton, Link } from "@mui/material"

import {
  Pinterest as PinterestIcon,
  Facebook as FacebookIcon,
  YouTube as YouTubeIcon,
  Instagram as InstagramIcon,
} from "@mui/icons-material"

const CustomButton = props => (
  <IconButton
    aria-label={props.name}
    color="primary"
    size="large"
    sx={{ border: "solid 1px" }}
    component={Link}
    href={props.url}
    target="_blank"
    rel="noopener noreferrer"
  >
    {props.icon}
  </IconButton>
)

const SocialIcons = () => {
  return (
    <Stack direction="row" spacing={2}>
      <CustomButton
        name="facebook"
        url="https://www.facebook.com/maqualfr"
        icon={<FacebookIcon />}
      />

      <CustomButton
        name="pinterest"
        url="https://www.pinterest.fr/maqualfr"
        icon={<PinterestIcon />}
      />

      <CustomButton
        name="youtube"
        url="https://www.youtube.com/channel/UCZrEHddhihMdhxXQkjXV5yA"
        icon={<YouTubeIcon />}
      />

      <CustomButton
        name="instagram"
        url="https://www.instagram.com/maqual.fr/"
        icon={<InstagramIcon />}
      />
    </Stack>
  )
}

export default SocialIcons
