import { format, addHours } from "date-fns"
import frLocale from "date-fns/locale/fr"

export const getVisitor = () => {
  const visitor =
    typeof window !== "undefined" && localStorage.getItem("visitor")
  if (visitor) {
    // console.log("visitor", visitor)
    return visitor
  } else {
    const nextVisitor = format(
      addHours(new Date(), -1),
      "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
      { locale: frLocale }
    )
    // console.log("nextVisitor", nextVisitor)
    typeof window !== "undefined" &&
      localStorage.setItem("visitor", nextVisitor)
    return nextVisitor
  }

  // const currentDate = format(addHours(new Date(), -1), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'", { locale: frLocale })
  // const visitor = JSON.parse(localStorage.getItem("visitor"))
  // if (visitor && typeof visitor === "object") {
  //   const nextVisitor = {
  //     firstDate: visitor.firstDate,
  //     lastDate: currentDate,
  //   }
  //   typeof window !== 'undefined' && localStorage.setItem("visitor", JSON.stringify(nextVisitor))
  // } else {
  //   const nextVisitor = {
  //     firstDate: currentDate,
  //     lastDate: currentDate,
  //   }
  //   typeof window !== 'undefined' && localStorage.setItem("visitor", JSON.stringify(nextVisitor))
  // }
}
