import React from "react"

import { Link as RouterLink } from "gatsby"

import {
  Container,
  Box,
  Divider,
  Grid,
  Typography,
  Link,
  Stack,
  useMediaQuery,
} from "@mui/material"

import logo from "../images/logo.svg"

import SocialIcons from "../components/social-icons"

const appUrl = "/decoupe-sur-mesure"

const CustomLink = props => {
  const { to, children } = props
  return (
    <Link component={RouterLink} to={to} underline="hover" color="inherit">
      {children}
    </Link>
  )
}

const Footer = () => {
  // console.log("Footer")

  const isMobile = useMediaQuery(theme => theme.breakpoints.down("lg"))

  const SloganContent = () => (
    <Box sx={{ textAlign: isMobile ? "center" : "left" }}>
      <Box sx={{ marginBottom: 4 }}>
        <b>Matériaux de qualité et découpe en forme pour Makers</b>
      </Box>

      <Typography variant="body2" component="div">
        <Box sx={{ marginBottom: 2 }}>
          <Link component={RouterLink} to="/contact/">
            <b>contact@maqual.fr</b>
          </Link>
        </Box>
        <Box>
          <b>+33 6 35 38 59 09</b>
        </Box>
      </Typography>
    </Box>
  )

  return (
    <Box>
      <Divider />
      <Container component={Box} sx={{ marginY: 12.5 }}>
        <Stack spacing={12.5}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent={isMobile ? "center" : "space-between"}
          >
            <RouterLink to="/">
              <Box sx={{ height: "32px" }}>
                <img src={logo} alt="logo" height="100%" />
              </Box>
            </RouterLink>

            {!isMobile && (
              <Stack
                direction="row"
                spacing={2}
                divider={<Divider orientation="vertical" flexItem />}
              >
                <Box>Vous avez un projet spécifique ?</Box>
                <Link component={RouterLink} to={appUrl + "/"}>
                  <b>Demander un devis</b>
                </Link>
              </Stack>
            )}
          </Stack>

          {isMobile && <SloganContent />}

          {!isMobile && (
            <Box sx={{ flexGrow: 1 }}>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={6}
              >
                <Grid item xs={6} md={3}>
                  <SloganContent />
                </Grid>
                <Grid item xs={6} md={3}>
                  <Box sx={{ marginBottom: 4 }}>
                    <b>Nos matériaux</b>
                  </Box>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    <Stack spacing={2}>
                      <CustomLink to={appUrl + "/bois/"}>Bois</CustomLink>
                      <CustomLink to={appUrl + "/metal/"}>Métal</CustomLink>
                      <CustomLink to={appUrl + "/plastique/"}>
                        Plastique
                      </CustomLink>
                      <CustomLink to={appUrl + "/verre-et-miroir/"}>
                        Verre
                      </CustomLink>
                    </Stack>
                  </Typography>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Box sx={{ marginBottom: 4 }}>
                    <b>La découpe</b>
                  </Box>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    <Stack spacing={2}>
                      <CustomLink to="/formes/">Formes</CustomLink>
                      <CustomLink to="/angles/">Angles</CustomLink>
                      <CustomLink to="/chants/">Chants</CustomLink>
                      <CustomLink to="/finitions/">Finitions</CustomLink>
                    </Stack>
                  </Typography>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Box sx={{ marginBottom: 4 }}>
                    <b>Besoin d'aide ?</b>
                  </Box>
                  <Typography
                    variant="body2"
                    component="div"
                    color="text.secondary"
                  >
                    <Stack spacing={2}>
                      <CustomLink to="/questions/">
                        Questions fréquentes
                      </CustomLink>
                      <CustomLink to="/contact/">Nous contacter</CustomLink>
                      <CustomLink to="/concept/">Le concept</CustomLink>
                    </Stack>
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          )}

          <Stack
            direction={isMobile ? "column-reverse" : "row"}
            alignItems="center"
            justifyContent="space-between"
            spacing={8}
          >
            <Typography variant="caption" component="div" color="text.primary">
              <b>
                <Stack
                  direction={isMobile ? "column" : "row"}
                  spacing={isMobile ? 2 : 4}
                  divider={<Divider orientation="vertical" flexItem />}
                  sx={{ textAlign: isMobile ? "center" : "left" }}
                >
                  <Box>© Maqual 2021</Box>
                  <CustomLink to="/confidentialite/">
                    Confidentialité & cookies
                  </CustomLink>
                  <CustomLink to="/mentions-legales/">
                    Mentions légales
                  </CustomLink>
                </Stack>
              </b>
            </Typography>

            <SocialIcons />
          </Stack>
        </Stack>
      </Container>
    </Box>
  )
}

export default Footer
