import React from "react"

import { Link as RouterLink } from "gatsby"

import {
  Box,
  Typography,
  Link,
  Container,
  Stack,
  Divider,
  useMediaQuery,
  IconButton,
  MenuItem,
  Tooltip,
  Fab,
  Popper,
  Fade,
  Paper,
  ClickAwayListener,
} from "@mui/material"

import { Menu as MenuIcon } from "@mui/icons-material"

import logo from "../images/logo.svg"

import ShapeIcon from "../images/steps/shape.inline.svg"

const items = [
  { label: "Découpe sur mesure", path: "/decoupe-sur-mesure/" },
  {
    label: "Nos matériaux",
    path: "/materiaux/",
    items: [
      { label: "Bois", path: "/decoupe-sur-mesure/bois/" },
      { label: "Métal", path: "/decoupe-sur-mesure/metal/" },
      { label: "Plastique", path: "/decoupe-sur-mesure/plastique/" },
      { label: "Verre", path: "/decoupe-sur-mesure/verre-et-miroir/" },
    ],
  },
  { label: "Besoin d'aide ?", path: "/aide/" },
]

const paperStyle = {
  overflow: "visible",
  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
  marginTop: 1.5,
  paddingY: 2,
  "&:before": {
    content: '""',
    display: "block",
    position: "absolute",
    top: 0,
    left: 20,
    width: 10,
    height: 10,
    bgcolor: "background.paper",
    transform: "translateY(-50%) rotate(45deg)",
    zIndex: 0,
  },
}

const Header = () => {
  // console.log("Header")

  const isMobile = useMediaQuery(theme => theme.breakpoints.down("lg"))

  const MobileMenu = () => {
    return (
      <React.Fragment>
        <CustomPopper
          component={
            <IconButton color="primary">
              <MenuIcon fontSize="large" />
            </IconButton>
          }
        >
          {items.map(x => (
            <React.Fragment key={x.path}>
              <MenuItem
                component={RouterLink}
                to={x.path}
                sx={{ paddingX: 3, paddingY: 1.5 }}
              >
                <b>{x.label}</b>
              </MenuItem>
              {x.items?.length > 0 &&
                x.items.map(x => (
                  <MenuItem
                    key={x.path}
                    component={RouterLink}
                    to={x.path}
                    sx={{
                      minHeight: 0,
                      paddingLeft: 5,
                      paddingRight: 3,
                      paddingY: 0.5,
                    }}
                  >
                    <Typography variant="body2" color="text.secondary">
                      {x.label}
                    </Typography>
                  </MenuItem>
                ))}
            </React.Fragment>
          ))}
          <Divider />

          <MenuItem
            component={RouterLink}
            to={"/contact/"}
            sx={{ paddingX: 3, paddingY: 2 }}
          >
            <Stack spacing={1}>
              <Typography variant="caption">
                Vous avez un projet spécifique ?
              </Typography>
              <b>Contactez-nous</b>
            </Stack>
          </MenuItem>
        </CustomPopper>
      </React.Fragment>
    )
  }

  const CustomPopper = props => {
    const { component, children } = props

    const [anchorEl, setAnchorEl] = React.useState(null)
    const [open, setOpen] = React.useState(false)

    const onClick = e => {
      if (isMobile) {
        setAnchorEl(e.currentTarget)
        setOpen(!open)
      }
    }
    const onClickAway = () => {
      setOpen(false)
    }
    const onMouseEnter = e => {
      if (!isMobile) {
        setAnchorEl(e.currentTarget)
        setOpen(true)
      }
    }
    const onMouseLeave = () => {
      if (!isMobile) {
        setOpen(false)
      }
    }

    return (
      <ClickAwayListener onClickAway={onClickAway}>
        <Box>
          <Box onClick={onClick} onMouseEnter={onMouseEnter}>
            {component}
          </Box>
          <Popper
            open={open}
            anchorEl={anchorEl}
            placement="bottom-start"
            transition
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <Paper onMouseLeave={onMouseLeave} sx={paperStyle}>
                  {children}
                </Paper>
              </Fade>
            )}
          </Popper>
        </Box>
      </ClickAwayListener>
    )
  }

  const DesktopMenu = () => {
    const ItemLink = props => {
      const { to, children } = props
      return (
        <Link
          key={to}
          color="inherit"
          underline="hover"
          component={RouterLink}
          to={to}
        >
          <b>{children}</b>
        </Link>
      )
    }

    return (
      <React.Fragment>
        {items.map(x => (
          <React.Fragment key={x.path}>
            {x.items?.length > 0 ? (
              <CustomPopper
                component={<ItemLink to={x.path}>{x.label}</ItemLink>}
              >
                {x.items.map(x => (
                  <MenuItem
                    key={x.path}
                    component={RouterLink}
                    to={x.path}
                    sx={{ paddingX: 3, paddingY: 1.5 }}
                  >
                    <b>{x.label}</b>
                  </MenuItem>
                ))}
              </CustomPopper>
            ) : (
              <ItemLink to={x.path}>{x.label}</ItemLink>
            )}
          </React.Fragment>
        ))}
      </React.Fragment>
    )
  }

  return (
    <Box>
      <Typography variant="body2" component="div">
        <Box
          sx={{
            color: "white",
            bgcolor: "primary.main",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingY: 1.5,
          }}
        >
          Votre estimation en quelques clics
        </Box>
      </Typography>

      <Container>
        <Stack
          direction="row"
          spacing={isMobile ? 2 : 4}
          alignItems="center"
          justifyContent="space-between"
          sx={{ paddingY: 5 }}
        >
          {isMobile && <MobileMenu />}

          <RouterLink to="/">
            <Box sx={{ height: "32px" }}>
              <img src={logo} alt="logo" height="100%" />
            </Box>
          </RouterLink>

          {isMobile && (
            <Tooltip title="Découpe sur mesure">
              <RouterLink to="/decoupe-sur-mesure/">
                <Fab
                  color="primary"
                  size="medium"
                  sx={{
                    minWidth: 48,
                    minHeight: 48,
                    marginLeft: "24px !important",
                  }}
                >
                  <ShapeIcon fill={"white"} width="24px" height="24px" />
                </Fab>
              </RouterLink>
            </Tooltip>
          )}

          {!isMobile && <DesktopMenu />}

          {!isMobile && <Box sx={{ flexGrow: 1 }} />}

          {useMediaQuery(theme => theme.breakpoints.up("lg")) && (
            <React.Fragment>
              <Stack
                direction="row"
                spacing={2}
                divider={<Divider orientation="vertical" flexItem />}
              >
                <Box>Vous avez un projet spécifique ?</Box>
                <Link component={RouterLink} to="/contact/">
                  <b>Contactez-nous</b>
                </Link>
              </Stack>
            </React.Fragment>
          )}
        </Stack>
      </Container>
    </Box>
  )
}

export default Header
